<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动首页图"
                    prop="img_id">
        <ImgUpload :fileList="imgList"
                   multiple
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="用户推广图"
                    prop="recommend_img_id">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.recommend_img_id" />
      </el-form-item>

      <el-form-item label="办理奖品名称"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入办理奖品名称"
                  v-model="form.transaction_prize_name"></el-input>
      </el-form-item>
      <el-form-item label="办理奖品图片">
        <ImgUpload :fileList="imgList3"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.transaction_prize_img_id" />
      </el-form-item>

      <el-form-item label="办理奖品说明">
        <Editor v-model="form.transaction_prize_desc" />
      </el-form-item>
      <el-form-item label="推广成功奖品名称"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入推广成功奖品名称"
                  v-model="form.promote_prize_name"></el-input>
      </el-form-item>
      <el-form-item label="推广成功奖品图片">
        <ImgUpload :fileList="imgList4"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.promote_prize_img_id" />
      </el-form-item>

      <el-form-item label="推广成功奖品说明">
        <Editor v-model="form.promote_prize_desc" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="兑奖地址选择"
                    prop="img_id">
        <el-input type="text"
                  placeholder="请输入经度"
                  class="mr-15"
                  style="width:150px"
                  v-model="form.longitude"></el-input>
        <el-input type="text"
                  placeholder="请输入纬度"
                  style="width:150px"
                  class="mr-15"
                  v-model="form.latitude"></el-input>
        <el-button type="primary"
                   @click="$refs.map.initMap({lat:form.latitude,lng:form.longitude})">地图定位</el-button>
        <Map @on-click="choseLocation"
             ref="map" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import Map from '@/components/Map'
import { setBusiness, businessDetail } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Area from '@/components/Area'
import Editor from '@/components/Editor'
import { getImgId } from '@/utils'
export default {
  name: 'SetLucky',
  data () {
    return {
      imgList: [],
      imgList2: [],
      imgList3: [],
      imgList4: [],
      areaAddress: {},
      dateTime: [],
      form: {
        name: '',
        rule: '',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        started_at: '',
        ended_at: '',
        img_id: [],
        recommend_img_id: '',
        transaction_prize_name: '',
        transaction_prize_img_id: '',
        transaction_prize_desc: '',
        promote_prize_name: '',
        promote_prize_img_id: '',
        promote_prize_desc: '',
        longitude: '',
        latitude: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        list_province: [
          { required: true, message: '至少选择省份', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        img_id: [
          { type: 'array', required: true, message: '请上传首页图片', trigger: 'change' }
        ],
        recommend_img_id: [
          { required: true, message: '请上传用户推广图', trigger: 'change' }
        ],
        transaction_prize_img_id: [
          { required: true, message: '请上传办理业务奖品图', trigger: 'change' }
        ],
        promote_prize_img_id: [
          { required: true, message: '请上传推广奖励奖品图', trigger: 'change' }
        ],
        promote_prize_name: [
          { required: true, message: '请输入办理业务奖品名', trigger: 'blur' }
        ],
        transaction_prize_name: [
          { required: true, message: '请输入办理业务奖品名', trigger: 'blur' }
        ],
        transaction_prize_desc: [
          { required: true, message: '请输入办理业务奖品描述', trigger: 'blur' }
        ],
        promote_prize_desc: [
          { required: true, message: '请输入推广奖励奖品描述', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '请选择地图', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    Area,
    ImgUpload,
    Map,
    Editor
  },
  computed: {

  },
  methods: {
    // 地图组件回调
    choseLocation (res) {
      this.form.longitude = res.location.lng
      this.form.latitude = res.location.lat
    },
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(
            JSON.stringify(this.form)
          )
          const res = await setBusiness(formData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('business')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async businessDetail (id) {
      const res = await businessDetail({
        id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        this.imgList = [...activity.img_data]
        activity.recommend_img_id = getImgId(activity.recommend_img_data)
        activity.recommend_img_data && this.$set(this.imgList2, 0, activity.recommend_img_data)
        activity.transaction_prize_img_id = getImgId(activity.transaction_prize_img_data)
        activity.transaction_prize_img_data && this.$set(this.imgList3, 0, activity.transaction_prize_img_data)
        activity.promote_prize_img_id = getImgId(activity.promote_prize_img_data)
        activity.promote_prize_img_data && this.$set(this.imgList4, 0, activity.promote_prize_img_data)

        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }

        this.dateTime = [activity.started_at, activity.ended_at]
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.businessDetail(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
