<template>
  <div class="">
    <el-dialog title="地图选址"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="50%">
      <el-input placeholder="请输入内容"
                v-model="addressText">
        <el-button type="primary"
                   slot="append"
                   @click="searchAdress"
                   icon="el-icon-search"></el-button>
      </el-input>
      <div id="tx-map"
           class="mt-15">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="choseLocation">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
const qq = window.qq
// import Map from './map'
export default {
  name: '',
  data () {
    return {
      addressText: '',
      address: {},
      dialogVisible: false,
      map: null,
      geocoder: null,
      loading: true,
      marker: null
    }
  },
  methods: {
    // 地址解析，搜索地址
    searchAdress () {
      this.geocoder.getLocation(this.addressText)
    },
    initMap (location) {
      this.dialogVisible = true
      const that = this
      const citylocation = new qq.maps.CityService({
        complete: function (result) {
          var center = null
          if (location && location.lat && location.lng) {
            center = new qq.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng))
          } else {
            center = result.detail.latLng
          }
          that.map = new qq.maps.Map(document.getElementById('tx-map'), {
            // 地图的中心地理坐标。
            center: center,
            zoom: 13
          })
          // 设置标记
          that.marker = new qq.maps.Marker({
            position: center,
            draggable: true,
            map: that.map
          })
          // 设置反地址解析对象
          that.geocoder = new qq.maps.Geocoder({
            error () {
              that.$message.error('请输入准确地址')
            },
            complete (result) {
              // self.location = result
              that.address = result.detail
              that.$emit('change', result.detail)
              that.addressText = result.detail.address
              that.map.setCenter(result.detail.location)
              that.marker.setPosition(result.detail.location)
            }
          })

          that.geocoder.getAddress(center)
          // 添加可拖动事件
          qq.maps.event.addListener(that.marker, 'dragend', function () {
            // getPosition()  返回Marker的位置
            const input = that.marker.getPosition()
            const lat = parseFloat(input.lat)
            const lng = parseFloat(input.lng)
            const latLng = new qq.maps.LatLng(lat, lng)
            that.geocoder.getAddress(latLng)
          })
          // 添加点击事件
          qq.maps.event.addListener(that.map, 'click', function (event) {
            that.geocoder.getAddress(event.latLng)
            that.marker.setPosition(event.latLng)
          })
          that.loading = false
        }
      })
      // 调用searchLocalCity();方法    根据用户IP查询城市信息。
      citylocation.searchLocalCity()
    },
    choseLocation () {
      this.address.location.lat = this.address.location.lat.toString()
      this.address.location.lng = this.address.location.lng.toString()
      this.$emit('on-click', this.address)
      this.dialogVisible = false
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
#tx-map {
  min-width: 100%;
  min-height: 400px;
}
</style>
